import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      redirect: "/login",
      children: [
        {
          name: "Dashboard",
          path: "/users",
          component: () => import("@/views/dashboard/Dashboard"),
        },
        {
          path: "/reset_password",
          component: () => import("@/views/dashboard/pages/ResetPassword"),
        },    
      ],
    },
    {
      path: "/login",
      component: () => import("@/views/dashboard/pages/Login"),
    },
    {
      path: "/logout",
      component: () => import("@/views/dashboard/pages/Logout"),
    },
  ],
});
